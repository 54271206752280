var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',{staticClass:"pt-0"},[_c('div',[_c('v-breadcrumbs',{staticClass:"px-0 mb-4",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('v-row',{staticClass:"mb-4",attrs:{"justify":"space-between","dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('h2',[_vm._v("Reporte")])]),_c('v-col',{attrs:{"cols":"12","md":"auto"}})],1),_c('div',{staticClass:"wrapper-filters mb-4"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","hide-details":"","clearable":"","dense":"","solo":""},on:{"change":() => _vm.listenFilters(),"click:append":() => _vm.listenFilters()},model:{value:(_vm.filters.q),callback:function ($$v) {_vm.$set(_vm.filters, "q", $$v)},expression:"filters.q"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('date-picker',{attrs:{"placeholder":"Periodo","format":"DD-MM-YYYY","type":"date","range":""},on:{"input":(val) => _vm.listenDatePicker(val)},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1)],1),_c('v-card',{attrs:{"loading":_vm.loadingData}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.agreements,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.user_create`,fn:function({ item }){return [(item?.user)?_c('span',{domProps:{"textContent":_vm._s(item?.user?.fullName)}}):_vm._e()]}},{key:`item.owner`,fn:function({ item }){return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
									name: 'SearchAgreement',
									query: { owner: item.owner.name },
								}}},[_c('span',{domProps:{"textContent":_vm._s(item.owner.name)}})])]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(_vm.getContractValue(item, 'start-date')))+" ")]}},{key:`item.endDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(_vm.getContractValue(item, 'end-date')))+" ")]}},{key:`item.confirm`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getApprovalDate(item))+" ")]}},{key:`item.position`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getCurrentArea(item?.status))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-btn',{staticClass:"text-none primary--text",attrs:{"color":"transparent","small":"","text":""},on:{"click":() => _vm.openDialog(item.agreementForm)}},[_vm._v(" Ficha Contractual "),_c('v-icon',{attrs:{"light":"","right":"","small":""}},[_vm._v(" mdi-file-document-outline ")])],1),_c('v-btn',{staticClass:"text-none primary--text",attrs:{"color":"transparent","to":{
									name: 'ReadAgreement',
									params: { agreementId: item.id },
								},"small":"","text":""}},[_vm._v(" Ver Registro "),_c('v-icon',{attrs:{"light":"","right":"","small":""}},[_vm._v(" mdi-link ")])],1)]}}],null,true)}),(_vm.agreementsPagination && _vm.agreementsPagination.pages > 1)?_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.agreementsPagination.pages || 0},on:{"input":() => _vm.listenFilters()},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}}):_vm._e()],1)],1)],1)]),_c('report-dialog',{attrs:{"itemList":_vm.itemsAgreement},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }