import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import ReportDialog from '@/components/agreement/elements/ReportDialog/ReportDialog.vue';
import DownloadAgreement from '@/components/agreement/elements/DownloadAgreement/DownloadAgreement.vue';
import { stateList } from '@/helpers/statusAgreement.js';
import moment from 'moment';
import { formatDate } from '@/helpers/globalHelpers';

export default {
	name: 'AgreementReport',
	props: {},
	data: () => ({
		headers: [
			{ text: 'Contrato', value: 'name', sortable: false, class: 'primary--text' },
			{
				text: 'Tipo',
				value: 'agreementType.name',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Cliente/proveedor',
				value: 'owner',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Fecha de inicio',
				value: 'created_at',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Fecha de fin',
				value: 'endDate',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Usuario creador',
				value: 'user_create',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Fecha de aprobación',
				value: 'confirm',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Área actual',
				value: 'position',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: '',
				value: 'actions',
				sortable: false,
				class: 'primary--text',
				align: 'end',
			},
		],
		loadingData: false,
		filters: {
			q: null,
			page: 1,
			status: null,
			date_to: null,
			date_from: null,
			pagination: true,
			per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
		},
		formDialog: false,
		itemsAgreement: [],
		statusList: stateList,
		dateRange: null,
		momentFormat: {
			stringify: (date) => {
				moment.locale('es');
				return date ? moment(date).format('MMMM') : '';
			},
		},
	}),
	computed: {
		...mapState('agreement', ['agreements', 'agreementsPagination']),

		breadcrumbs: function () {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Abastecimiento',
					link: true,
					exact: true,
					disable: false,
					to: {
						name: 'AgreementHome',
					},
				},
				{
					text: 'Reporte',
					disabled: true,
					href: '/',
				},
			];
		},
	},
	watch: {},
	created() {
		this.getAgreements();
	},
	methods: {
		...mapActions('agreement', [
			'readAgreements',
			'cleanAgreements',
			'searchAgreement',
		]),

		showAlert(color = '', msm = '') {
			$EventBus.$emit('showSnack', color || 'success', msm);
		},

		async getAgreements() {
			this.loadingData = true;
			const { ok, error } = await this.searchAgreement(this.filters);
			if (!ok) {
				let msm = error?.data?.message;
				msm = Array.isArray(msm) ? msm.join(', ') : msm;
				this.showAlert('error', msm);
			}
			this.loadingData = false;
		},

		openDialog(items = []) {
			this.itemsAgreement = items;
			this.formDialog = true;
		},

		listenFilters() {
			this.getAgreements();
		},

		listenDatePicker(val) {
			moment.locale('es');
			this.filters.date_from = val[0] ? moment(val[0]).format('YYYY-MM-DD') : null;
			this.filters.date_to = val[1] ? moment(val[1]).format('YYYY-MM-DD') : null;
			this.getAgreements();
		},

		formatDate(date) {
			return formatDate(date, 'LL');
		},

		getContractValue(agreement, key) {
			const items = agreement?.agreementForm;
			if (Array.isArray(items)) {
				const result = items.find((item) => item?.fieldAgreement?.key === key);
				return result?.value || '-';
			}
			return '-';
		},

		getApprovalDate(agreement) {
			if (
				agreement?.status === 'LEGAL_APROBACION_APROBADO' &&
				agreement?.updated_at
			) {
				return formatDate(agreement?.updated_at, 'lll');
			} else {
				return '-';
			}
		},
		getCurrentArea(status) {
			const statusList = [
				{ status: 'LEGAL_REVISION', area: 'Legal' },
				{ status: 'LEGAL_APROBACION', area: 'Legal' },
				{ status: 'LEGAL_APROBACION_PREAPROBADO', area: 'Legal' },
				{ status: 'LEGAL_APROBACION_APROBADO', area: 'Legal' },
				{ status: 'PROVEEDOR_APROBACION', area: 'Proveedor' },
			];
			const found = statusList.find((item) => item?.status === status);
			return found ? found?.area : 'Abastecimiento';
		},
	},

	beforeDestroy() {
		this.cleanAgreements();
	},
	components: {
		ReportDialog,
		DownloadAgreement,
	},
};
