import moment from 'moment';

export default {
	name: 'ReportDialog',
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		itemList: {
			type: Array,
			required: false,
		},
	},
	data() {
		return {
		};
	},
	created() {},
	mounted() {},
	computed: {
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {},
	methods: {
		closeDialog() {},
		formatDate(date) {
			if (!isNaN(new Date(date))) {
				moment.locale('es');
				return date ? moment(date).format('LL') : null;
			} else {
				return null;
			}
		},
	},
	components: {},
};
