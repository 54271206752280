<template>
	<agreement-report />
</template>
<script>
import AgreementReport from '@/components/agreement/report/AgreementReport.vue';

export default {
	name: 'AgreementReportView',
	components: { AgreementReport },
};
</script>
