import { urlApi } from '@/store/api/config';
export default {
	name: 'DownloadAgreement',
	props: {},
	data: () => ({}),
	computed: {
		url() {
			return `${urlApi}/agreement/download`;
		},
	},
	created() {},
	methods: {},
	beforeDestroy() {},
	components: {},
};
